import { cloneDeep } from 'lodash';
import moment, { LocaleSpecification } from 'moment';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { TimeIndicatorContainer } from './TimeIndicatorContainer';
import { translateText } from './utils';

interface TextElementElementProps {
  panelProps: TextPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
  mainIndicator: { timeControls: TimeControlDef; value: string; dateValue: string }[];
  timeFrameIndicator: string;
  relativeTime: boolean;
}
export const IndicatorElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  mainIndicator,
  timeFrameIndicator,
  relativeTime,
}: TextElementElementProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeAspectRatio, translation, activeScene, sceneTranslation } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const {
    boxDef,
    fontSize,
    fontFamily,
    fontType,
    fontColor,
    fontAlignment,
    textAnimation,
    timeControls,
    positionControl,
    strokeWidth,
    strokeColor,
    textTransform,
  } = panelProps;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  /**** custom locale ****/
  const indicatorLocale = moment;
  const extractTimeFormat = (inputFormat: string) => {
    const tokens = cloneDeep(inputFormat).split(/\s+/);
    const timeTokens = tokens.filter((token) => /[HhmAas]+/.test(token));
    return timeTokens.length > 0 ? timeTokens.join(' ') : '';
  };
  const dict = sceneTranslation[activeScene]?.customWords || translation?.customWords;

  const parseLocale = useCallback(
    (trans: any): moment.LocaleSpecification => {
      return trans
        ? {
            ...trans,
            longDateFormat: {
              ...trans.longDateFormat,
              LT: extractTimeFormat(timeFrameIndicator),
              L: timeFrameIndicator,
            },
            meridiem: (hour: number, minute: number, isLowercase: boolean) => {
              if (hour < 12) {
                return isLowercase ? trans.meridiem.am : trans.meridiem.AM;
              } else {
                return isLowercase ? trans.meridiem.pm : trans.meridiem.PM;
              }
            },
            calendar: {
              sameDay: `[${trans.calendar?.sameDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              nextDay: `[${trans.calendar?.nextDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              nextWeek: `${timeFrameIndicator} [${trans.calendar?.nextWeek}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              lastDay: `[${trans.calendar?.lastDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              lastWeek: `${timeFrameIndicator} [${trans.calendar?.lastWeek}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              sameElse: 'LT',
            },
          }
        : undefined;
    },
    [timeFrameIndicator],
  );
  useEffect(() => {
    const trans = sceneTranslation[activeScene] ?? translation;
    trans &&
      indicatorLocale.defineLocale('custom', parseLocale(trans) as unknown as LocaleSpecification);
    indicatorLocale.locale(trans ? 'custom' : 'en-US');
    !trans &&
      indicatorLocale.defineLocale('en-US', {
        calendar: {
          sameDay: `[Today] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          nextDay: `[Tomorrow] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          /*           nextWeek: `${
          timeFrameIndicator.includes('dddd') ? '[Next]' : '[Next week]'
        } ${timeFrameIndicator}`, */
          lastDay: `[Yesterday] ${extractTimeFormat(timeFrameIndicator) ? 'LT' : ''}`,
          /*  lastWeek: `${
          timeFrameIndicator.includes('dddd') ? '[Last]' : '[Last week]'
        } ${timeFrameIndicator}`, */
          sameElse: timeFrameIndicator,
        },
      });
    indicatorLocale(undefined, timeFrameIndicator).calendar(null);
  }, [
    activeScene,
    indicatorLocale,
    parseLocale,
    sceneTranslation,
    timeFrameIndicator,
    translation,
    dict,
    sceneTranslation[activeScene],
  ]);

  const fontUnit = (canvas.cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  const { color } = background;
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;
  const backgroundClip = fontColor.toString().includes('linear-gradient') ? 'text' : 'initial';
  const bgColor = color?.includes('linear-gradient')
    ? gradientOpacity(color)
    : singleColorOpacity(color);
  useFontLoader(fontFamily);

  const getValue = [...new Set(mainIndicator || [])]?.find((val) => {
    if (val.timeControls.startMS <= time && val.timeControls.endMS >= time) {
      return val.value;
    }
  });
  function renderStringWithPlaceholders(
    inputString?: string | null,
    valueObject: Record<string, string> = {},
  ) {
    const regex = /\$(\w+)/g;
    if (!dict)
      return inputString
        ?.replace(/\$Night/g, 'Night')
        .replace(/\$NIGHT/g, 'NIGHT')
        .replace(/\$Day/g, 'Day')
        .replace(/\$DAY/g, 'DAY')
        .replace(/\$Morning/g, 'Morning')
        .replace(/\$MORNING/g, 'MORNING')
        .replace(/\$Evening/g, 'Evening')
        .replace(/\$EVENING/g, 'EVENING');
    return inputString?.replace(regex, (match, placeholder) => {
      return (valueObject && valueObject[placeholder]) || match;
    });
  }
  const formattedDate = (date?: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  }) => {
    const parsedDate = moment(date?.dateValue, 'DD-MM-YYYY HH:mm');
    if (!date) {
      return null;
    }
    const formattedDate = parsedDate.calendar();
    {
      try {
        // console.log(date.value);
        return relativeTime ? `${formattedDate}` : date.value; ///parsedDate.format(newFormat);
      } catch (error) {
        console.log(`Error parsing date: ${error}`);
      }
    }
  };
  return (
    <TimeIndicatorContainer
      visibility={true}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'textPanels'}
      lock={false}
      style={{
        fontFamily: fontFamily + ' ' + fontType,
        textTransform,
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: Math.floor(fontUnit * fontSize),
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      {formattedDate(getValue) && (
        <div
          style={{
            height: '100%',
            background: bgColor,
          }}
        >
          <div
            className="text-panel-container"
            key={fontColor + 'container'}
            style={{
              height: '100%',
              overflow: 'hidden',
              background: backgroundClip === 'initial' ? 'transparent' : gradientOpacity(fontColor),
              paddingTop: fontUnit * paddingTop,
              paddingLeft: fontUnit * paddingLeft,
              paddingRight: fontUnit * paddingRight,
              paddingBottom: fontUnit * paddingBottom,
              borderLeft: borderString(borderLeft),
              borderRight: borderString(borderRight),
              borderTop: borderString(borderTop),
              borderBottom: borderString(borderBottom),
              WebkitBackgroundClip: backgroundClip,
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <div
                style={{
                  height: '100%',
                  minWidth: textAnimation.active ? '100%' : 'auto',
                  width: textAnimation.active ? 'fit-content' : 'auto',
                  whiteSpace: 'nowrap',
                  //
                  // marginTop: fontSize * 1.15,
                  lineHeight: 1.15,
                  textOverflow: 'hidden',
                  transform: textAnimation?.active
                    ? translateText(
                        positionControl.w,
                        activeAspectRatio,
                        time,
                        ref,
                        textAnimation.speed,
                        timeControls[0].startMS,
                        MAX_FULLSCREEN_HEIGHT,
                        textAnimation.direction,
                      )
                    : 'none',
                  WebkitTextStroke: strokeWidth
                    ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                    : '0px',
                }}
                ref={ref}
              >
                {renderStringWithPlaceholders(formattedDate(getValue), dict)}
              </div>
            </div>
          </div>
        </div>
      )}
    </TimeIndicatorContainer>
  );
};
