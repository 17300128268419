import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { WeatherDataMapLayer } from '../../../model/definitions/WeatherDataMapLayer';

export interface FirstLayerRange {
  firstFrameTimestamp: number;
  lastFrameTimestamp: number;
}

export const getFirstLayerRange = (map: MapPanelDef): FirstLayerRange | null => {
  const layers: WeatherDataMapLayer[] = [];

  if (map.wdSpace[0].gribMapLayers.length) {
    const foundLayer = map.wdSpace[0].gribMapLayers.find((l) => l.enabled);
    if (foundLayer) layers.push(foundLayer);
  }
  if (map.wdSpace[0].radarMapLayers.length) {
    const foundLayer = map.wdSpace[0].radarMapLayers.find((l) => l.enabled);
    if (foundLayer) layers.push(foundLayer);
  }
  if (map.wdSpace[0].satelliteMapLayers.length) {
    const foundLayer = map.wdSpace[0].satelliteMapLayers.find((l) => l.enabled);
    if (foundLayer) layers.push(foundLayer);
  }

  layers.sort((a, b) => a.creationTime - b.creationTime);

  if (!layers[0]) {
    return null;
  }

  const framesSorted = layers[0].dataFrames;

  return {
    firstFrameTimestamp: framesSorted[0]?.timestamp,
    lastFrameTimestamp: framesSorted[framesSorted.length - 1]?.timestamp,
  };
};

export const getDefaultRange = (
  firstRange: FirstLayerRange,
  frames: DataFrameDef[],
): number[] | null => {
  const exactFirstTimestampIndex = frames.findIndex(
    (f) => f.timestamp == firstRange.firstFrameTimestamp,
  );
  const exactLastTimestampIndex = frames.findIndex(
    (f) => f.timestamp == firstRange.lastFrameTimestamp,
  );

  const timespanBetweenFrames = firstRange.lastFrameTimestamp - firstRange.firstFrameTimestamp;

  // #1 - try to find exact match
  if (exactFirstTimestampIndex > -1 && exactLastTimestampIndex > -1) {
    return [exactFirstTimestampIndex, exactLastTimestampIndex];
  }

  // #2 - if the frames are in the past, select the next frames with similar distance
  if (firstRange.lastFrameTimestamp < frames[0].timestamp) {
    const searchingTimestamp = frames[0].timestamp + timespanBetweenFrames;
    return [0, getClosestIndex(searchingTimestamp, frames)];
  }

  // #3 - if the frames are in the future, select the past frames with similar distance
  if (firstRange.firstFrameTimestamp > frames[frames.length - 1].timestamp) {
    const searchingTimestamp = frames[frames.length - 1].timestamp - timespanBetweenFrames;
    return [getClosestIndex(searchingTimestamp, frames), frames.length - 1];
  }

  // #4 - get the closest possible range for both timestamps
  const closest = [
    getClosestIndex(firstRange.firstFrameTimestamp, frames),
    getClosestIndex(firstRange.lastFrameTimestamp, frames),
  ];

  if (closest[0] === 0 && closest[1] === 0) {
    return null;
  }

  return closest;
};

const getClosestIndex = (timestamp: number, frames: DataFrameDef[]) => {
  let smallestDistance = frames[frames.length - 1].timestamp - frames[0].timestamp,
    smallestDistanceIndex = 0;
  frames.forEach((frame, idx) => {
    if (Math.abs(timestamp - frame.timestamp) < smallestDistance) {
      smallestDistance = Math.abs(timestamp - frame.timestamp);
      smallestDistanceIndex = idx;
    }
  });
  return smallestDistanceIndex;
};
