import { ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createPlayer } from '../../../../../components/timeline/helpers';
import { ElementsEnum } from '../../../../../core/ui/enums/ElementsEnum';
import { BackgroundDef } from '../../../../../model/definitions/BackgroundDef';
import { BoxDef } from '../../../../../model/definitions/BoxDef';
import { C9ProjectDef } from '../../../../../model/definitions/C9ProjectDef';
import {
  ActiveDef,
  setProjectToPlay,
  setPropertyGridActiveHash,
} from '../../../../../store/slices/active-slice';
import {
  updateBoxDef,
  updateForecastMapLayerBox,
  updateIndicatorBox,
  updateObservedMapLayerBox,
} from '../../../../../store/slices/project-slice';
import { RootState } from '../../../../../store/store';
import InputNumber from '../../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';
import styles from '../../Properties.module.scss';
import GridItem from '../../shared/GridItem';

interface BackgroundProps {
  background: BackgroundDef;
  elementType: ElementsEnum;
  parent?: string;
  isIndependent?: boolean;
  mapId?: string;
}
export const BackgroundProperties = ({
  background,
  elementType,
  parent,
  isIndependent,
  mapId,
}: BackgroundProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activeScene, activeElement, activePoster, syncSpace, activeFramerate } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const isGradient = background.color?.toString().includes('linear-gradient');

  const [savedGradient, setSavedGradient] = useState<string>(
    isGradient
      ? background.color
      : 'linear-gradient(45deg,rgba(255, 255, 255, 255), rgba(255, 255, 255, 0))',
  );
  const [savedColor, setSavedColor] = useState<string>(
    !isGradient && background.color ? background.color : 'rgba(255, 255, 255, 255)',
  );

  useEffect(() => {
    if (isGradient) {
      setSavedGradient(background.color);
    } else {
      setSavedColor(background.color);
    }
  }, [background]);

  const dispatch = useDispatch();
  const { color } = background;
  function onFocus(path: Leaves<BoxDef | BackgroundDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  const updateBox = (propertyPath: Leaves<BoxDef | BackgroundDef>, value: string) => {
    onFocus(propertyPath);
    elementType === ElementsEnum.INDICATOR
      ? dispatch(updateIndicatorBox({ activeScene, propertyPath, value, parentId: parent }))
      : elementType === ElementsEnum.OBSERVED_WD && mapId && !parent
      ? dispatch(
          updateObservedMapLayerBox({
            newValue: value,
            activeScene,
            propertyPath,
            parentId: mapId,
            id: activeElement,
          }),
        )
      : elementType === ElementsEnum.FORECAST_WD && mapId && !parent
      ? dispatch(
          updateForecastMapLayerBox({
            newValue: value,
            activeScene,
            propertyPath,
            parentId: mapId,
            id: activeElement,
          }),
        )
      : dispatch(
          updateBoxDef({
            activeScene,
            elementType,
            activeElement,
            propertyPath,
            value,
            parentId: activePoster,
          }),
        );
    dispatch(
      setProjectToPlay({
        projectToPlay: createPlayer(project, syncSpace, activeFramerate),
      }),
    );
  };

  const parseBackLinearGradient = (index: number) => {
    const colors = savedGradient?.match(/\d+/g);
    let value: string[] = [];
    if (colors) {
      value = [
        colors[0],
        `rgba(${colors[1]}, ${colors[2]},${colors[3]},${colors[4]})`,
        `rgba(${colors[5]}, ${colors[6]},${colors[7]},${colors[8]})`,
      ];
    }
    return value[index] ?? '0';
  };
  const getBackGradientArray = () => {
    const colors = savedGradient.match(/\d+/g);
    if (colors)
      return [
        colors[0],
        `rgba(${colors[1]}, ${colors[2]},${colors[3]},${colors[4]})`,
        `rgba(${colors[5]}, ${colors[6]},${colors[7]},${colors[8]})`,
      ];
  };
  const onChangeBackGradient = (color: string, index: number) => {
    const newGradient = getBackGradientArray();
    if (newGradient) newGradient[index] = color;
    updateBox(
      isIndependent ? 'color' : 'background.color',
      newGradient
        ? `linear-gradient(${newGradient[0]}deg,${newGradient[1]},${newGradient[2]})`
        : '',
    );
    setSavedGradient(
      newGradient
        ? `linear-gradient(${newGradient[0]}deg,${newGradient[1]},${newGradient[2]})`
        : '',
    );
  };
  const rgba = color
    .substring(4, color.length - 1)
    .replace(/ /g, '')
    .substring(1, color.length - 1)
    .split(',');
  return (
    <>
      <h4 style={{ marginTop: '0' }}>Background</h4>
      <div className="grid prop-grid-4">
        <GridItem
          noBorderBg
          label={'Transparent:'}
          item={
            <ToggleSwitch
              label={''}
              key={color}
              checked={Number(rgba[3]) === 0}
              onChange={(e) => {
                console.log(e);
                updateBox(
                  isIndependent ? 'color' : 'background.color',
                  `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${e ? 0 : 255})`,
                );
              }}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Color:'}
          item={
            <PaletteColorPicker
              disabled={isGradient}
              value={color}
              onChange={(e) =>
                !isGradient && updateBox(isIndependent ? 'color' : 'background.color', e)
              }
            />
          }
        />

        <GridItem
          noBorderBg
          label={'Gradient:'}
          item={
            <ToggleSwitch
              label={''}
              checked={isGradient}
              onChange={(e) => {
                e ? setSavedColor(background.color) : setSavedGradient(background.color);
                updateBox(
                  isIndependent ? 'color' : 'background.color',
                  e ? savedGradient : savedColor,
                );
              }}
            />
          }
        />
      </div>
      {isGradient && (
        <>
          <h4>Gradient</h4>
          <div className="grid prop-grid-4">
            <GridItem
              noBorderBg
              label={'From:'}
              item={
                <PaletteColorPicker
                  value={parseBackLinearGradient(1)}
                  onChange={(e) => onChangeBackGradient(e, 1)}
                  disabled={!isGradient}
                />
              }
            />

            <GridItem
              noBorderBg
              label={'To:'}
              item={
                <PaletteColorPicker
                  value={parseBackLinearGradient(2)}
                  onChange={(e) => onChangeBackGradient(e, 2)}
                  disabled={!isGradient}
                />
              }
            />

            <GridItem
              noBorderBg
              label={'Degree:'}
              item={
                <>
                  <Slider
                    min={0}
                    max={360}
                    value={parseInt(parseBackLinearGradient(0))}
                    disabled={!isGradient}
                    onChange={(e) => {
                      e && typeof e === 'number' && onChangeBackGradient(e.toString(), 0);
                    }}
                  />
                  <InputNumber
                    className={styles.inputWrap}
                    max={360}
                    min={0}
                    precision={2}
                    value={parseBackLinearGradient(0)}
                    onInputChange={(e) => {
                      e >= 0 && e <= 360 && onChangeBackGradient(e.toString(), 0);
                    }}
                    type="number"
                  />
                </>
              }
            />
          </div>
        </>
      )}
    </>
  );
};
