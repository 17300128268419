/* eslint-disable react-hooks/exhaustive-deps */
import './style.scss';

import { InputNumber } from 'antd';
import * as duration from 'duration-fns';
import Slider from 'rc-slider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BiVideoRecording } from 'react-icons/bi';
import { MdOutlineCloseFullscreen } from 'react-icons/md';
import { PiArrowUUpRight } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../atoms/button/Button';
import { createPlayer, findClosestToMidnight } from '../../components/timeline/helpers';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { convertMsToFrames } from '../../helpers/convertMsToFrames';
import {
  getMaxZindex,
  getProjectDuration,
  getScenePlaybackLength,
  totalSkippedTime,
} from '../../helpers/timelineUtil';
import { useScaleFactor } from '../../hooks/useScaleFactor';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { CountDown } from '../../molecules/CountDown';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef, setProjectToPlay, setTime } from '../../store/slices/active-slice';
import { addVideoRecorder } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import Player from '../player/Player';
import { VoiceOver } from '../voiceover/VoiceOver';
import style from './style.module.scss';
import useTimeUpdater from './useTimeUpdater';

interface PlayerProps {
  toStart: () => void;
  backFive: () => void;
  start: () => void;
  pause: () => void;
  stop: () => void;
  skipFive: () => void;
  toEnd: () => void;
  skip?: (e: number) => void;
  fullScreen?: boolean;
  canvas?: boolean;
  passRef?: React.RefObject<HTMLDivElement>;
  onClose?(): void;
  prevScene: (e: number) => void;
  nextScene: (e: number) => void;
}

const readableTime = (time: number, canvas: boolean) => {
  const normal = duration.normalize(Math.round(time ? time : 0));
  return ` ${String('0' + normal.hours).slice(-2)}:${String('0' + normal.minutes).slice(
    -2,
  )}:${String('0' + normal.seconds).slice(-2)}${!canvas ? ';' : ''}`;
};
const SecondaryControls: React.FC<PlayerProps> = ({
  toStart,
  backFive,
  start,
  pause,
  stop,
  skipFive,
  toEnd,
  skip,
  fullScreen = false,
  canvas,
  passRef,
  onClose,
  prevScene,
  nextScene,
}) => {
  const { activeFramerate, mode, activeScene, previewSize, syncSpace, activeMap } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState<string>();
  const contextValue = useContext(PlayerContext);
  const project = useSelector<RootState>((state) => state.project.present.project) as C9ProjectDef;
  const scene = project.sceneDefs.find((scene) => scene.id === activeScene);
  const [idle, setIdle] = useState(true);
  const scaleFactor = useScaleFactor(fullScreen);
  const [hovering, setHovering] = useState(false);
  const { localTime, utcTime, timeZone } = useTimeUpdater(contextValue, scene);
  const [currentMap, setCurrentMap] = useState(scene?.mapPanels[0]?.id);
  const space = scene?.mapPanels.find((map) => map.id === currentMap)?.wdSpace[0];
  const [deltaDisabled, setDeltaDisabled] = useState(!space?.layerSync);

  useEffect(() => {
    space && setDeltaDisabled(!space?.layerSync);
  }, [scene, activeMap, currentMap, space]);

  useEffect(() => {
    if (scene) {
      const map = scene.mapPanels[0]?.id;
      setCurrentMap(map);
    }
  }, [scene]);

  const times = space?.indicator;

  // const deltaMin = useMemo(() => {
  //   return times ? findClosestToMidnight(times, 0)?.min : 0;
  // }, [times]);

  // const deltaMax = useMemo(() => {
  //   return times ? findClosestToMidnight(times, 0)?.max : 0;
  // }, [times]);

  useEffect(() => {
    setCurrentTime(readableTime(contextValue.time, !!canvas));
  }, [contextValue.time]);
  const fullTime = () => {
    const scene = project.sceneDefs.find((scene) => scene.id === (activeScene as string));
    if (mode === ModeEnum.SEQUENCE && scene) return getScenePlaybackLength(scene);
    else return getProjectDuration(project);
  };
  const controlsRef = useRef<HTMLDivElement>(null);
  const timerId = useRef<NodeJS.Timer>();
  useEffect(() => {
    project &&
      dispatch(
        setProjectToPlay({ projectToPlay: createPlayer(project, syncSpace, activeFramerate) }),
      );
  }, [project.videoOver]);
  useEffect(() => {
    const handleMove = () => {
      if (passRef?.current && fullScreen) passRef.current.style.cursor = 'auto';
      if (controlsRef?.current && fullScreen) controlsRef.current.style.display = 'flex';
      clearTimeout(timerId.current);
      timerId.current = undefined;
      setIdle(false);
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setIdle(true);
        if (passRef?.current && fullScreen) passRef.current.style.cursor = 'none';
        if (controlsRef?.current && fullScreen) controlsRef.current.style.display = 'none';
      }, 1500);
    };
    fullScreen && window.addEventListener('mousemove', handleMove, true);
    return () => {
      window.removeEventListener('click', handleMove);
    };
  });
  const deltaRef = useRef<HTMLInputElement>(null);
  const addRecorder = () => {
    const videoLayer = new VideoPanelDef();
    videoLayer.name = 'Video Over';
    videoLayer.positionControl = new PositionControlDef(32, 32);
    videoLayer.positionControl.x = 0;
    videoLayer.positionControl.y = 0;
    videoLayer.positionControl.zindex = getMaxZindex(activeScene as string, project);
    videoLayer.timeControls = [new TimeControlDef(contextValue.time, getProjectDuration(project))];
    dispatch(addVideoRecorder({ recorder: videoLayer }));
  };
  return (
    <div
      ref={controlsRef}
      className={style.secondaryControls}
      style={{
        justifyContent: fullScreen ? 'center' : 'inherit',
        height: canvas ? 'auto' : 75,
        outline: 'none',
        //transform: `scale(${scaleInverse})`,
        zIndex: fullScreen ? 9999 : '',
        transform: `scale(${fullScreen ? 1 / scaleFactor : 1})`,
      }}
      tabIndex={0}
      id="secondary-controls"
    >
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className={`flex justify-center items-center ${canvas && style.secondaryControlsCanvas} ${
          canvas && idle && !hovering && ''
        }`}
        style={{
          width: !canvas ? (16 * previewSize) / 9 + 2 : '450px',
          zIndex: fullScreen ? 999 : 'auto',
        }}
      >
        {onClose && (
          <div className={style.controlBarInControl}>
            <div className={style.fullscreenIcon} onClick={onClose}>
              <MdOutlineCloseFullscreen color={'white'} size={16} />
            </div>
          </div>
        )}
        <Player
          project={project}
          toStart={toStart}
          backFive={backFive}
          onPlay={start}
          onPause={pause}
          onStop={stop}
          skipFive={skipFive}
          toEnd={toEnd}
          prevScene={prevScene}
          nextScene={nextScene}
        />
        {canvas && fullScreen && (
          <div className={style.lowerPlayer}>
            <div className={`${style.numberContainer} flex gap-2`}>{currentTime}</div>
            <Slider
              min={0}
              max={
                mode === ModeEnum.SEQUENCE
                  ? project.sceneDefs.find((scene) => scene.id === activeScene)?.durationInMS
                  : project
                  ? getProjectDuration(project)
                  : 0
              }
              value={contextValue.time}
              handleRender={(origin) => origin}
              className={style.slider}
              onChange={(e) => {
                typeof e === 'number' && contextValue.setTime(e);
                typeof e === 'number' &&
                  contextValue.isPlaying === PlaybackEnum.PLAYING &&
                  skip &&
                  skip(e);
                typeof e === 'number' && dispatch(setTime({ activeTime: e }));
              }}
            />
            <div className={style.numberContainer}>
              {readableTime(fullTime() > 0 ? fullTime() : 1, canvas)}
            </div>
          </div>
        )}
        <span className="text-white text-xs">
          {localTime && utcTime && `Time: ${localTime} (${timeZone}) ${utcTime} (UTC)`}
        </span>
        {!canvas && (
          <div className={'flex items-center gap-x-2 mr-2'}>
            <div className={'ml-4 flex items-center gap-2'}>
              <div>Δt:</div>
              <InputNumber
                suffix={'h'}
                ref={deltaRef}
                // min={deltaMin}
                // max={deltaMax}
                disabled={deltaDisabled || contextValue.isPlaying === PlaybackEnum.PLAYING}
                key={syncSpace.toString()}
                onChange={(value) => {
                  const time = times
                    ? findClosestToMidnight(
                        times,
                        Number(value) ?? 0,
                        scene?.startDate,
                        scene?.endDate,
                        space.timeControls[0],
                      )?.value
                    : null;

                  if (time !== null && time !== undefined && !isNaN(time) && time >= 0) {
                    contextValue.setTime(time);
                    dispatch(setTime({ activeTime: time }));
                    if (skip) {
                      skip(time);
                    }
                  }
                }}
              />
            </div>
            <button
              className={
                'h-full flex items-center justify-around bg-green-700 px-1 py-[9px] rounded'
              }
              onClick={() => {
                const value = deltaRef.current?.value;
                const time = times ? findClosestToMidnight(times, Number(value) ?? 0)?.value : null;
                time && contextValue.setTime(time);
                time && dispatch(setTime({ activeTime: time }));
                if (skip) {
                  time && skip(time);
                }
              }}
            >
              <PiArrowUUpRight />
            </button>
          </div>
        )}
      </div>
      {!canvas && (
        <div className={`${style.timeDisplay} pl-[10px]`}>
          <div>
            <span>
              {currentTime}
              {(convertMsToFrames(activeFramerate, contextValue.time) % activeFramerate)
                .toString()
                .padStart(2, '0')}
            </span>
            /
            <span>
              {readableTime(
                mode === ModeEnum.SEQUENCE
                  ? project?.sceneDefs?.find((scene) => scene.id === activeScene)?.durationInMS ?? 0
                  : getProjectDuration(project) ?? 0,
                !!canvas,
              )}
              {(
                convertMsToFrames(
                  activeFramerate,
                  mode === ModeEnum.SEQUENCE
                    ? project?.sceneDefs?.find((scene) => scene.id === activeScene)?.durationInMS ??
                        0
                    : getProjectDuration(project) ?? 0,
                ) % activeFramerate
              )
                .toString()
                .padStart(2, '0')}
            </span>
          </div>
          {mode === ModeEnum.PROJECT && (
            <>
              <div className={'flex items-center'}>
                <VoiceOver
                  projectName={project.name}
                  start={start}
                  duration={getProjectDuration(project)}
                />
                <Button
                  style={{ width: 29, height: 29, borderRadius: 50, padding: 0 }}
                  icon={<BiVideoRecording style={{ fill: 'black' }} />}
                  onClick={addRecorder}
                  shape={'round'}
                  buttonType={project.videoOverRecorder ? 'primary' : 'secondary'}
                  color={'#000'}
                />
                {/*  <TimelineActions /> */}
              </div>
              <CountDown
                time={Math.round(contextValue.time)}
                duration={getProjectDuration(project)}
                skippedTime={totalSkippedTime(project)}
                framerate={activeFramerate}
                skips={project.skippedTime}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SecondaryControls;
