import { groupBy } from 'lodash';
import React, { Fragment } from 'react';

import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { findMaxInArray } from '../../helpers/timelineUtil';
import { LogicalGroupElement } from '../../model/definitions/LogicalGroupElement';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { WeatherDataSpaceDef } from '../../model/definitions/WeatherDataSpaceDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { GroupingEnum } from '../../model/UI/enums/GroupingEnum';
import { PanelDefs } from '../../model/UI/PanelDef';
import FlyOverLane from './flyOverLane/FlyOverLane';
import { findGroupMembers, getGroupTimes, insetGroupingPanel, spaceSpan } from './helpers';
import { LayerLane } from './layerLane/LayerLane';
import { ObservedPointLane } from './layerLane/ObservedLane';
import { PosterLane } from './layerLane/PosterLane';
import DrawLaneRefactor from './timelane/DrawLaneRefactor';
import { ForecastGroupLane } from './timelane/ForecastGroupLane';
import { GroupLane } from './timelane/GroupLane';
import Lane from './timelane/Lane';
import WDSpaceLane from './timelane/WDSpaceLane';

type NestedObject = { [key: string]: any };

export function extractPropertyValues(arrays: NestedObject[][], propertyPath: string): any[] {
  const propertyValues: any[] = [];

  arrays.forEach((innerArray) => {
    innerArray.forEach((obj) => {
      let propertyValue: any = obj;
      const pathSegments = propertyPath.split('.');

      for (const segment of pathSegments) {
        if (propertyValue && typeof propertyValue === 'object' && segment in propertyValue) {
          propertyValue = propertyValue[segment];
        } else {
          propertyValue = undefined;
          break;
        }
      }

      if (propertyValue !== undefined) {
        propertyValues.push(propertyValue);
      }
    });
  });

  return propertyValues;
}
export function splitArrayByNestedProperty<T extends NestedObject>(
  arr: T[],
  propertyPath: string,
): { [key: string]: T[] } {
  const splitArrays: { [key: string]: T[] } = {};

  arr.forEach((obj) => {
    let propertyValue: any = obj;
    const pathSegments = propertyPath.split('.');

    for (const segment of pathSegments) {
      if (propertyValue && typeof propertyValue === 'object' && segment in propertyValue) {
        propertyValue = propertyValue[segment];
      } else {
        propertyValue = undefined;
        break;
      }
    }

    if (propertyValue !== undefined) {
      if (!splitArrays[propertyValue]) {
        splitArrays[propertyValue] = [];
      }
      splitArrays[propertyValue].push(obj);
    }
  });
  return splitArrays;
}
export function pointForLane<T>(splitArrays: { [key: string]: T[] }) {
  const data = [];
  for (const name in splitArrays) {
    data.push(splitArrays[name]);
  }
  return data;
}
export function _pointTime<T extends { timeControls: TimeControlDef[] }>(
  arr: T[],
): { min: number; max: number } {
  const timeControls = arr.map((item) => item.timeControls);
  const min = extractPropertyValues(timeControls, 'startMS');
  const max = extractPropertyValues(timeControls, 'endMS');
  const minTime = Math.min(...min);
  const maxTime = Math.max(...max);
  return { min: minTime, max: maxTime };
}

const renderUngrouped = (
  elements: SceneDef = new SceneDef(),
  hide: Array<SceneKeys<SceneDef>> = [],
  duration: number,
  activeZoom: number,
  open: boolean,
  displayedFlyOvers: Array<string>,
  groupMode: GroupingEnum,
  sceneStart?: number | null,
  sceneEnd?: number | null,
) => {
  const panels = [
    elements?.textPanels.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.TEXT,
        elementType: 'textPanels',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.timestampPanels?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.TIMESTAMP,
        elementType: 'timestampPanels',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.weatherPosters.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.WEATHER_GRAPH,
        elementType: 'weatherPosters',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.imagePanels.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.IMAGE,
        elementType: 'imagePanels',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.animationPanels.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.ANIMATION,
        elementType: 'animationPanels',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.videoPanels.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.VIDEO,
        elementType: 'videoPanels',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.mapPanels.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.MAP,
        elementType: 'mapPanels',
        flyOver: el.flyOver,
        volume: undefined,
      };
    }),
    elements?.audioElements.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.AUDIO,
        elementType: 'audioElements',
        wdSpace: [new WeatherDataSpaceDef()],
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.observedWDElements.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.OBSERVED_WD,
        elementType: 'observedWDElements',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.forecastWDElements.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.FORECAST_WD,
        elementType: 'forecastWDElements',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.pointDates?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.POINT_DATE,
        elementType: 'pointDates',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
    elements?.pointLocation?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.POINT_LOCATION,
        elementType: 'pointLocation',
        wdSpace: [new WeatherDataSpaceDef()],
        volume: undefined,
        geoPosters: null,
        drawingElements: null,
      };
    }),
  ]
    .flat()
    .filter(Boolean);
  return (
    elements &&
    panels.map((item, index) => {
      return (
        <React.Fragment key={item?.id}>
          {item?.elType !== ElementsEnum.MAP && item?.elType !== ElementsEnum.WEATHER_GRAPH && (
            <>
              {insetGroupingPanel(
                elements,
                item?.id as string,
                item?.elementType as SceneKeys<SceneDef>,
                groupMode,
                duration,
                hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) >= 0,
              )}
              {hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <Lane
                  key={`Lane_0_${item?.id}`}
                  element={item}
                  elementId={item!.id}
                  duration={duration}
                  type={item!.elType}
                  name={item?.name ?? `${typeof item + index}`}
                  segments={item?.timeControls}
                  zoom={activeZoom}
                  open={open}
                />
              )}
            </>
          )}
          {item?.elType === ElementsEnum.WEATHER_GRAPH && (
            <>
              {insetGroupingPanel(
                elements,
                item?.id as string,
                item?.elementType as SceneKeys<SceneDef>,
                groupMode,
                duration,
                hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) >= 0,
              )}
              {hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <Lane
                  key={`Lane_graph_${item.id}`}
                  element={item}
                  elementId={item!.id}
                  duration={duration}
                  type={item!.elType}
                  name={item?.name ?? `${typeof item + index}`}
                  segments={item?.timeControls}
                  open={open}
                />
              )}
            </>
          )}
          {item?.elType === ElementsEnum.WEATHER_GRAPH &&
            renderPosterItems(
              item as WeatherPosterDef,
              hide,
              duration,
              true,
              item.timeControls[0],
              item.id,
            )}
          {item?.elType === ElementsEnum.MAP && (
            <>
              {insetGroupingPanel(
                elements,
                item?.id as string,
                item?.elementType as SceneKeys<SceneDef>,
                groupMode,
                duration,
                hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) >= 0,
              )}
              {hide && hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <Lane
                  key={`Lane_map_${item.id}`}
                  element={item}
                  elementId={item!.id}
                  duration={duration}
                  type={item!.elType}
                  name={item?.name ?? `${typeof item + index}`}
                  segments={item?.timeControls}
                  open={open}
                />
              )}
            </>
          )}
          {
            //@ts-ignore
            item.flyOverEnabled &&
              displayedFlyOvers.includes(item?.id as string) &&
              hide &&
              hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <FlyOverLane
                  duration={duration}
                  length={findMaxInArray(item!.timeControls, 'endMS').endMS}
                  start={findMaxInArray(item!.timeControls, 'startMS').startMS}
                  style={{
                    margin: '0 0 30px 0',
                  }}
                  key={`${item?.id}_flyover`}
                  keyFrames={
                    //@ts-ignore
                    item?.flyOver?.keyFrames
                  }
                />
              )
          }
          {displayedFlyOvers.includes(item?.id as string) &&
            hide &&
            hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 &&
            item.drawingElements?.map((draw: any) => (
              <DrawLaneRefactor
                key={`Lane_map_${draw.id}`}
                element={draw}
                elementId={draw!.id}
                duration={duration}
                type={draw!.elType}
                name={draw?.name ?? `${typeof item + index}`}
                segments={draw?.timeControls}
                parentId={item.id}
                parentTime={item.timeControls[0]}
                open={displayedFlyOvers.includes(item?.id as string)}
              />
            ))}
          {item?.wdSpace.map(
            (space) =>
              displayedFlyOvers.includes(item?.id as string) &&
              hide &&
              (space.gribMapLayers.length > 0 ||
                space.radarMapLayers.length > 0 ||
                space.satelliteMapLayers.length > 0 ||
                space.symbolLayers?.length > 0 ||
                space.observedDataLayers.length > 0 ||
                space.forecastDataLayers.length > 0 ||
                space.vectorMapLayers.length > 0) &&
              hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <Fragment key={`Lane_map_${space.id}`}>
                  <WDSpaceLane
                    space={space}
                    parentTime={item.timeControls[0]}
                    key={`Lane_map_${space.id}`}
                    elementId={space.id}
                    duration={duration}
                    type={ElementsEnum.WD_SPACE}
                    parentId={item.id}
                    time={space.timeControls[0]}
                    sceneStart={sceneStart}
                    sceneEnd={sceneEnd}
                  />
                  {space.observedDataLayers?.map((layer, index) => (
                    <ObservedPointLane
                      parentId={space.id}
                      span={spaceSpan(space)}
                      enabled={layer.enabled}
                      parentTime={space.timeControls[0]}
                      key={layer.id}
                      time={layer.timeControls[0]}
                      duration={duration}
                      mapId={item.id}
                      index={index}
                      layerId={layer.id}
                      name={layer.name}
                      layer={layer}
                    />
                  ))}
                  {pointForLane(splitArrayByNestedProperty(space.forecastDataLayers, 'name')).map(
                    (segment, index) => (
                      <ForecastGroupLane
                        key={segment[0].id}
                        duration={duration}
                        segments={segment}
                        parentId={space.id}
                        parentTime={space.timeControls[0]}
                        span={spaceSpan(space)}
                        mapId={item.id}
                      />
                    ),
                  )}
                  {/* <div id={'Lane'}>
                    {space.forecastDataLayers?.map((layer, index) => (
                      <ForecastPointLane
                        parentId={space.id}
                        span={spaceSpan(space)}
                        enabled={layer.enabled}
                        parentTime={space.timeControls[0]}
                        key={layer.id}
                        time={layer.timeControls[0]}
                        duration={duration}
                        mapId={item.id}
                        index={index}
                        layerId={layer.id}
                        name={layer.name}
                        layer={layer}
                      />
                    ))}
                  </div> */}
                  {space.gribMapLayers?.map((layer, index) => (
                    <LayerLane
                      parentId={space.id}
                      span={spaceSpan(space)}
                      enabled={layer.enabled}
                      parentTime={space.timeControls[0]}
                      layerType={'gribMapLayers'}
                      key={layer.gribSource.id}
                      time={layer.timeControls[0]}
                      duration={duration}
                      frames={layer.dataFrames}
                      mapId={item.id}
                      index={index}
                      layerId={layer.id}
                      name={layer.name}
                      layer={layer}
                      layerSync={space.layerSync}
                      sceneStart={sceneStart}
                      sceneEnd={sceneEnd}
                      space={space}
                    />
                  ))}
                  {space.radarMapLayers?.map((layer, index) => (
                    <LayerLane
                      parentId={space.id}
                      span={spaceSpan(space)}
                      layerId={layer.id}
                      enabled={layer.enabled}
                      parentTime={space.timeControls[0]}
                      layerType={'radarMapLayers'}
                      key={layer.radarSource.id}
                      time={layer.timeControls[0]}
                      duration={duration}
                      frames={layer.dataFrames}
                      mapId={item.id}
                      index={index}
                      name={layer.name}
                      layer={layer}
                      layerSync={space.layerSync}
                      sceneStart={sceneStart}
                      sceneEnd={sceneEnd}
                      space={space}
                    />
                  ))}
                  {space.satelliteMapLayers?.map((layer, index) => (
                    <LayerLane
                      parentId={space.id}
                      span={spaceSpan(space)}
                      layerId={layer.id}
                      enabled={layer.enabled}
                      parentTime={space.timeControls[0]}
                      layerType={'satelliteMapLayers'}
                      key={layer.satelliteSource.id}
                      time={layer.timeControls[0]}
                      duration={duration}
                      frames={layer.dataFrames}
                      mapId={item.id}
                      index={index}
                      name={layer.name}
                      layer={layer}
                      layerSync={space.layerSync}
                      sceneStart={sceneStart}
                      sceneEnd={sceneEnd}
                      space={space}
                    />
                  ))}
                  {space.symbolLayers?.map((layer, index) => (
                    <LayerLane
                      parentId={space.id}
                      span={spaceSpan(space)}
                      enabled={layer.enabled}
                      parentTime={space.timeControls[0]}
                      layerType={'symbolLayers'}
                      key={layer.id}
                      time={layer.timeControls[0]}
                      duration={duration}
                      frames={layer.dataFrames}
                      mapId={item.id}
                      index={index}
                      layerId={layer.id}
                      name={layer.name}
                      layer={layer}
                      layerSync={space.layerSync}
                      sceneStart={sceneStart}
                      sceneEnd={sceneEnd}
                      space={space}
                    />
                  ))}
                </Fragment>
              ),
          )}
          {item?.geoPosters?.map(
            (layer, index) =>
              displayedFlyOvers.includes(item?.id as string) &&
              hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 && (
                <Fragment key={layer.id}>
                  <PosterLane
                    layerId={layer.id}
                    parentTime={item.timeControls[0]}
                    layerType={'satelliteMapLayers'}
                    key={layer.id}
                    time={layer.timeControls[0]}
                    duration={duration}
                    mapId={item.id}
                    index={index}
                  />
                  {renderPosterItems(
                    layer,
                    [],
                    duration,
                    open,
                    layer.timeControls[0],
                    layer.id,
                    true,
                    item.id,
                  )}
                </Fragment>
              ),
          )}
        </React.Fragment>
      );
    })
  );
};
const renderPosterItems = (
  elements: WeatherPosterDef,
  hide: Array<SceneKeys<SceneDef>> = [],
  duration: number,
  open: boolean,
  parentTime: TimeControlDef,
  parentId: string,
  isGeo?: boolean,
  mapId?: string,
) => {
  const panels = [
    elements?.textPanels?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.TEXT,
        elementType: 'textPanels',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.imagePanels?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.IMAGE,
        elementType: 'imagePanels',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.animationPanels?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.ANIMATION,
        elementType: 'animationPanels',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.videoPanels?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.VIDEO,
        elementType: 'videoPanels',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.observedWDElements?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.OBSERVED_WD,
        elementType: 'observedWDElements',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.forecastWDElements?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.FORECAST_WD,
        elementType: 'forecastWDElements',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.pointDates?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.POINT_DATE,
        elementType: 'pointDates',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
    elements?.pointLocation?.map((el) => {
      return {
        ...el,
        elType: ElementsEnum.POINT_LOCATION,
        elementType: 'pointLocation',
        gribMapLayers: null,
        radarMapLayers: null,
        satelliteMapLayers: null,
        volume: undefined,
      };
    }),
  ]
    .flat()
    .filter(Boolean);
  return elements && hide && hide.indexOf('weatherPosters' as SceneKeys<SceneDef>) < 0
    ? panels.map((item, index) => (
        <Lane
          key={`Lane_0_${item?.id}_${index}`}
          parentId={parentId}
          inPoster={true}
          element={item}
          elementId={item!.id}
          duration={duration}
          type={item!.elType}
          name={item?.name ?? `${typeof item + index}`}
          segments={item?.timeControls}
          open={open}
          parentTime={parentTime}
          isGeo={isGeo}
          mapId={mapId}
        />
      ))
    : [];
};
const renderFlyOverLane = (
  flyover: boolean,
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) => {
  return flyover &&
    displayedFlyOvers.includes(item?.id as string) &&
    hide &&
    hide.indexOf(item?.elementType as SceneKeys<SceneDef>) < 0 ? (
    <FlyOverLane
      duration={duration}
      length={findMaxInArray(item!.timeControls, 'endMS').endMS}
      start={findMaxInArray(item!.timeControls, 'startMS').startMS}
      style={{
        margin: '0 0 30px 0',
      }}
      key={`${item?.id}_flyover`}
      keyFrames={
        //@ts-ignore
        item?.flyOver?.keyFrames
      }
    />
  ) : (
    <Fragment key={`${item?.id}_flyover`}></Fragment>
  );
};

const renderDrawings = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) => {
  if (displayedFlyOvers.includes(item?.id as string) && hide)
    return item.drawingElements.map((draw, index) => (
      <DrawLaneRefactor
        key={`Lane_drawing_${draw.id}`}
        element={draw}
        elementId={draw!.id}
        parentId={item.id}
        parentTime={item.timeControls[0]}
        duration={duration}
        name={draw?.name ?? `${typeof item + index}`}
        segments={draw?.timeControls}
        open={true}
      />
    ));
  else return [<Fragment key={`Lane_drawing_0`}></Fragment>];
};
const renderObserved = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  item?.wdSpace[0].observedDataLayers?.map((layer, index) =>
    displayedFlyOvers.includes(item?.id as string) && hide ? (
      <ObservedPointLane
        span={spaceSpan(item.wdSpace[0])}
        parentId={item.wdSpace[0].id}
        layerId={layer.id}
        enabled={layer.enabled}
        parentTime={item.wdSpace[0].timeControls[0]}
        key={layer.id}
        time={layer.timeControls[0]}
        duration={duration}
        mapId={item.id}
        index={index}
        name={layer.name}
        layer={layer}
      />
    ) : (
      <Fragment key={layer.id}></Fragment>
    ),
  );
/* const renderForecast = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  pointForLane(splitArrayByNestedProperty(item?.wdSpace[0].forecastDataLayers, 'name')).map(
    (segment, index) => <ForecastGroupLane key={segment[0].id} />,
  ); */
const renderGrib = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  item?.wdSpace[0].gribMapLayers?.map((layer, index) =>
    displayedFlyOvers.includes(item?.id as string) && hide ? (
      <LayerLane
        span={spaceSpan(item.wdSpace[0])}
        parentId={item.wdSpace[0].id}
        layerId={layer.id}
        enabled={layer.enabled}
        parentTime={item.wdSpace[0].timeControls[0]}
        layerType={'gribMapLayers'}
        key={layer.gribSource.id}
        time={layer.timeControls[0]}
        duration={duration}
        frames={layer.dataFrames}
        mapId={item.id}
        index={index}
        name={layer.name}
        layer={layer}
        layerSync={item?.wdSpace[0].layerSync}
        space={item?.wdSpace[0]}
      />
    ) : (
      <Fragment key={layer.gribSource.id}></Fragment>
    ),
  );
const renderRadar = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  item?.wdSpace[0].radarMapLayers?.map((layer, index) =>
    displayedFlyOvers.includes(item?.id as string) && hide ? (
      <LayerLane
        span={spaceSpan(item.wdSpace[0])}
        parentId={item.wdSpace[0].id}
        layerId={layer.id}
        enabled={layer.enabled}
        parentTime={item.wdSpace[0].timeControls[0]}
        layerType={'radarMapLayers'}
        key={layer.radarSource.id}
        time={layer.timeControls[0]}
        duration={duration}
        frames={layer.dataFrames}
        mapId={item.id}
        index={index}
        name={layer.name}
        layer={layer}
        layerSync={item?.wdSpace[0].layerSync}
        space={item?.wdSpace[0]}
      />
    ) : (
      <Fragment key={layer.radarSource.id}></Fragment>
    ),
  );
const renderSatellite = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  item?.wdSpace[0].satelliteMapLayers?.map((layer, index) =>
    displayedFlyOvers.includes(item?.id as string) && hide ? (
      <LayerLane
        span={spaceSpan(item.wdSpace[0])}
        parentId={item.wdSpace[0].id}
        layerId={layer.id}
        enabled={layer.enabled}
        parentTime={item.wdSpace[0].timeControls[0]}
        layerType={'satelliteMapLayers'}
        key={layer.satelliteSource.id}
        time={layer.timeControls[0]}
        duration={duration}
        frames={layer.dataFrames}
        mapId={item.id}
        index={index}
        name={layer.name}
        layer={layer}
        layerSync={item?.wdSpace[0].layerSync}
        space={item?.wdSpace[0]}
      />
    ) : (
      <Fragment key={layer.satelliteSource.id}></Fragment>
    ),
  );
const renderSymbol = (
  displayedFlyOvers: Array<string>,
  item: MapPanelDef,
  duration: number,
  hide: Array<SceneKeys<SceneDef>> = [],
) =>
  item?.wdSpace[0].symbolLayers?.map((layer, index) =>
    displayedFlyOvers.includes(item?.id as string) && hide ? (
      <LayerLane
        span={spaceSpan(item.wdSpace[0])}
        parentId={item.wdSpace[0].id}
        layerId={layer.id}
        enabled={layer.enabled}
        parentTime={item.wdSpace[0].timeControls[0]}
        layerType={'symbolLayers'}
        key={layer.id}
        time={layer.timeControls[0]}
        duration={duration}
        frames={layer.dataFrames}
        mapId={item.id}
        index={index}
        name={layer.name}
        layer={layer}
        layerSync={item?.wdSpace[0].layerSync}
        space={item?.wdSpace[0]}
      />
    ) : (
      <Fragment key={layer.id}></Fragment>
    ),
  );
export const renderLogicalGrouping = (
  elements: SceneDef,
  duration: number,
  openGroups: string[],
  displayedFlyOvers: Array<string>,
  hide: Array<SceneKeys<SceneDef>> = [],
) => {
  const {
    textPanels,
    timestampPanels,
    weatherPosters,
    videoPanels,
    mapPanels,
    imagePanels,
    animationPanels,
    audioElements,
    observedWDElements,
    forecastWDElements,
    pointDates,
    pointLocation,
  } = elements;
  const parseData = (
    panels: PanelDefs[],
    elementType: ElementsEnum,
  ): Array<
    PanelDefs & { type: ElementsEnum } & {
      parent: string;
    } & {
      order: number;
    }
  > => {
    const data = panels.map((item) => {
      return {
        ...item,
        type: elementType,
        parent: item.parentGroups[0].groupId,
        order: item.parentGroups[0].orderNumInGroup,
      };
    });
    return data.filter(Boolean);
  };
  const groups = (): Array<
    LogicalGroupElement & { type: ElementsEnum } & { timeControls: TimeControlDef[] } & {
      parent: string;
    } & {
      order: number;
    }
  > =>
    elements.logicalGroups.map((group) => {
      return {
        ...group,
        type: ElementsEnum.GROUP,
        timeControls: [],
        parent: group.parentGroupId,
        order: group.orderNumInParentGroup,
      };
    });
  const parsed = [
    parseData(textPanels, ElementsEnum.TEXT),
    parseData(timestampPanels, ElementsEnum.TIMESTAMP),
    parseData(weatherPosters, ElementsEnum.WEATHER_GRAPH),
    parseData(videoPanels, ElementsEnum.VIDEO),
    parseData(mapPanels, ElementsEnum.MAP),
    parseData(imagePanels, ElementsEnum.IMAGE),
    parseData(animationPanels, ElementsEnum.ANIMATION),
    parseData(audioElements, ElementsEnum.AUDIO),
    parseData(observedWDElements, ElementsEnum.OBSERVED_WD),
    parseData(forecastWDElements, ElementsEnum.FORECAST_WD),
    parseData(pointDates, ElementsEnum.POINT_DATE),
    parseData(pointLocation, ElementsEnum.POINT_LOCATION),
    groups(),
  ].flat();
  const grouped = groupBy(parsed, 'parent');
  const lanes: JSX.Element[] = [];
  const render = (group = '00000000-0000-0000-0000-000000000000') => {
    grouped[group]
      ?.sort((a, b) => a.order - b.order)
      .map((item, index) => {
        if (item?.type === ElementsEnum.GROUP && openGroups.indexOf(item.parent) > -1) {
          lanes.push(
            <GroupLane
              times={getGroupTimes(findGroupMembers(elements, item.id), duration)}
              isOpen={openGroups.indexOf(item.id) < 0}
              groupName={item.name}
            />,
          );
        } else if (item?.type === ElementsEnum.MAP && openGroups.indexOf(item.parent) > -1) {
          const mapItem = item as MapPanelDef;
          lanes.push(
            <Lane
              key={`Lane_map_${item.id}`}
              element={item}
              elementId={item!.id}
              duration={duration}
              type={item!.type}
              name={item?.name ?? `${typeof item + index}`}
              segments={item?.timeControls}
            />,
            renderFlyOverLane(
              mapItem.flyOverEnabled,
              displayedFlyOvers,
              item as MapPanelDef,
              duration,
              hide,
            ),
            ...renderDrawings(displayedFlyOvers, item as MapPanelDef, duration, hide),
            displayedFlyOvers.includes(item?.id as string) &&
              hide &&
              (mapItem?.wdSpace[0].radarMapLayers.length ||
                mapItem?.wdSpace[0].satelliteMapLayers.length ||
                mapItem?.wdSpace[0].gribMapLayers.length ||
                mapItem?.wdSpace[0].symbolLayers.length ||
                mapItem?.wdSpace[0].vectorMapLayers.length) ? (
              <WDSpaceLane
                space={mapItem?.wdSpace[0]}
                parentTime={mapItem.timeControls[0]}
                key={`Lane_map_${mapItem?.wdSpace[0].id}`}
                elementId={mapItem?.wdSpace[0].id}
                duration={duration}
                type={ElementsEnum.WD_SPACE}
                parentId={mapItem?.id}
                time={mapItem?.wdSpace[0].timeControls[0]}
                sceneStart={elements.startDate}
                sceneEnd={elements.endDate}
              />
            ) : (
              <Fragment key={`Lane_map_${mapItem?.wdSpace[0].id}`}></Fragment>
            ),
            ...renderObserved(displayedFlyOvers, item as MapPanelDef, duration, hide),
            //...renderForecast(displayedFlyOvers, item as MapPanelDef, duration, hide),
            ...renderGrib(displayedFlyOvers, item as MapPanelDef, duration, hide),
            ...renderRadar(displayedFlyOvers, item as MapPanelDef, duration, hide),
            ...renderSatellite(displayedFlyOvers, item as MapPanelDef, duration, hide),
            ...renderSymbol(displayedFlyOvers, item as MapPanelDef, duration, hide),
            ...mapItem.geoPosters.map((layer, i) =>
              displayedFlyOvers.includes(item?.id as string) ? (
                <Fragment key={layer.id}>
                  <PosterLane
                    layerId={layer.id}
                    parentTime={item.timeControls[0]}
                    layerType={'satelliteMapLayers'}
                    key={layer.id}
                    time={layer.timeControls[0]}
                    duration={duration}
                    mapId={item.id}
                    index={i}
                  />
                  {renderPosterItems(
                    layer,
                    [],
                    duration,
                    false,
                    layer.timeControls[0],
                    layer.id,
                    true,
                    item.id,
                  )}
                </Fragment>
              ) : (
                <Fragment key={layer.id}></Fragment>
              ),
            ),
          );
        } else {
          // @ts-ignore
          if (item.type === 'weatherPoster') {
            lanes.push(
              ...renderPosterItems(
                item as WeatherPosterDef,
                hide,
                duration,
                true,
                item.timeControls[0],
                item.id,
              ),
            );
          } else if (
            item?.type === ElementsEnum.WEATHER_GRAPH &&
            openGroups.indexOf(item.parent) > -1
          ) {
            lanes.push(
              <Lane
                key={`Lane_map_${item.id}`}
                element={item}
                elementId={item!.id}
                duration={duration}
                type={item!.type}
                name={item?.name ?? `${typeof item + index}`}
                segments={item?.timeControls}
              />,
              ...renderPosterItems(
                item as WeatherPosterDef,
                hide,
                duration,
                true,
                item.timeControls[0],
                item.id,
              ),
            );
          } else if (openGroups.indexOf(item.parent) > -1) {
            lanes.push(
              <Lane
                key={`Lane_map_${item.id}`}
                element={item}
                elementId={item!.id}
                duration={duration}
                type={item!.type}
                name={item?.name ?? `${typeof item + index}`}
                segments={item?.timeControls}
              />,
            );
          }
        }
        item?.type === ElementsEnum.GROUP && render(item.id);
      });
  };
  render();
  return lanes;
};
export { renderUngrouped };
