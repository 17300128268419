export const FILTER_EMBOSS_FRAG = `
#ifdef GL_ES
precision mediump float;
precision mediump int;
#endif
#define PROCESSING_TEXTURE_SHADER

varying vec4 vertTexCoord;

uniform sampler2D texture;
uniform vec2 resolution;
uniform float emboss_fact;
uniform int u_is_satellite;

vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}


void main()
{
    float step_x = 3.0 / resolution.x;
    float step_y = 3.0 / resolution.y;

    vec2 offset[9];
    offset[0] = vec2(-step_x, -step_y);
    offset[1] = vec2( 0.0,    -step_y);
    offset[2] = vec2( step_x, -step_y);
    offset[3] = vec2(-step_x,  0.0);
    offset[4] = vec2( 0.0,     0.0);
    offset[5] = vec2( step_x,  0.0);
    offset[6] = vec2(-step_x,  step_y);
    offset[7] = vec2( 0.0,     step_y);
    offset[8] = vec2( step_x,  step_y);

    float kernel[9];
    kernel[0] =  -0.5;
    kernel[1] = -1.5;
    kernel[2] = -2.5;
    
    if (u_is_satellite==1) {
        kernel[3] =  0.8;
        kernel[4] =  0.8;
        kernel[5] = -0.8;
    } else {
        kernel[3] =  0.6;
        kernel[4] =  0.6;
        kernel[5] = -0.6;
    }
    kernel[6] =  2.5;
    kernel[7] =  1.5;
    kernel[8] =  0.5;

    vec3 result = vec3(0.0);
    float alpha = texture2D(texture, vertTexCoord.st).a;

    for (int i = 0; i < 9; i++)
    {
        vec4 color = texture2D(texture, vertTexCoord.st + offset[i]);
        result += color.rgb * kernel[i];
    }

    vec4 embossedColor = vec4(result, alpha);
    float e_p = embossedColor.r;
    float e_v = 1.0 + (e_p - 0.5) / 0.5;
    float e_a = (e_v - 1.0) * (emboss_fact / 10.0) + 1.15;

    vec4 origColor = texture2D(texture, vertTexCoord.st);
    vec3 hsv = rgb2hsv(origColor.rgb);
    hsv.z = (hsv.z - 0.4) * e_a + 0.5;
    hsv.z = clamp(hsv.z, 0.0, 1.0);

    vec3 color_adjusted = hsv2rgb(hsv);
    gl_FragColor = vec4(color_adjusted, alpha);
}`;
